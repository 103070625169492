.File-container
{
    margin-left: 15px;
    margin-right: 15px;
    
    width: fit-content;
}

.File-icons
{
    /*background-color: rgba(0,255,0,0.2);*/
    display: flex;
    justify-content: center;
}

.File-icons > button
{
    background-color: transparent;
    border-radius: 5px;
    /*border:none;*/

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    width:40px; height:64px;

    color:#fff;
    font-family: Augusta, 'Times New Roman', Times, serif;
    font-size:40px;
}

.File-container > a
{
    font-family: 'Times New Roman', Times, serif;
    font-size:x-large;
}

.primary{ color:var(--primary_light); }
.primary:visited{ color:var(--primary_dark); }

.File-protector
{
    background-color: rgba(0,0,0,0.85);

    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100vw; height: 100vh;
    position:fixed;
    left:0;top:0;
    z-index: 10;

    color:#fff;
}
.File-protector > fieldset
{
    border-radius: 25px;
    width:75%;
}
.File-protector > fieldset > legend > button
{
    background-color: transparent;
    border: solid 1px #fff;
    border-radius: 5px;

    cursor: pointer;

    margin-left:10px;

    color: #fff;
    font-size: xx-large;
}
.File-protector > fieldset > legend > button:active,
.File-protector > fieldset > legend > button:hover
{
    background-color: rgba(255,255,255,0.2);
}

.File-protector span
{
    color: var(--primary)
}
.File-protector p
{
    text-align: left;
}