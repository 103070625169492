.Language-container
{
    margin:0;
    margin-left:auto; margin-right: auto;
    width:80%;

    color:#fff;
}
.Languages_btn {
    background-color: transparent;
    border: solid 4px #fff;
    border-radius: 15em;
  
    cursor: pointer;
  
    padding:20px;
  
    color:#fff;
    font-size: x-large;
  }
  .Languages_btn:active, .Languages_btn:hover {
    background-color: rgba(255,255,255,0.15);
  }