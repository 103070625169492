@font-face {
  font-family: 'Augusta';
  src: url('../fonts/Augusta.ttf') format('truetype');
}

.App {
  text-align: center;
  height:100%;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header
{
  /*background-color: rgba(255,255,255,0.2);*/
  /*FLEX*/
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin: 0; padding: 0;

  /*SIZE*/
  width: 100%; height: calc(100vh + 20px);

  /*TEXT*/
  font-size: calc(10px + 6vmin);
  color: white;
}

.App-header-title
{
  font-family: Augusta, 'Comic sans ms';
  /*font-size: 60px;*/
  font-style: italic;
  text-align: start;
}