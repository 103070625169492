.Section-container
{    
    margin:0;
    margin-left:auto; margin-right: auto;
    margin-bottom: 60px;

    width:80%;

    color:#fff;
}

.Section-container > fieldset
{
    border-radius: 25px;
    padding-top: 5px;
    padding-bottom: 15px;
}

.Section-container > fieldset > legend
{
    color: var(--primary_light);
}

.clr-white{ filter:invert(100%); }
.invert-y{ transform: rotate(180deg); }