#auth_form, .auth_fieldset{ display: flex;  }
.protector
{
    background-color: rgba(0,0,0,0.7);

    display: none;

    width: 100vw; height: 100vh;
    position:fixed;
    left:0;top:0;
    z-index: 10;

    color:#fff;
}
.protector, #auth_form, .auth_fieldset
{
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#auth_form
{
    border: dotted 2px #fff;
    border-radius: 15px;
    padding: 25px;
}
.auth_fieldset
{
    border-radius: 25px;
}
.Authentication_btn {
    background-color: transparent;
    border: solid 2px #fff;
    border-radius: 15em;
  
    cursor: pointer;
  
    margin-top: 15px;
    padding:20px;
  
    color:#fff;
    font-size: x-large;
  }
  .Authentication_btn:active, .Authentication_btn:hover {
    background-color: rgba(255,255,255,0.15);
  }