.space_corner
{
    width: var(--space_side);
    height: var(--space_side);

    font-family: Augusta;
    font-size: xx-large;
    font-weight: bold;
}

.space
{
  border-radius: 10px;
  
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
}

.pos-top-left
{
    left:0;top:0;
    border-radius: 100% 5px 5px 5px;
    
}
.pos-top-right{ right:0;top:0;  border-radius: 5px 100% 5px 5px;}
.pos-bottom-left{ left:0;bottom:0;  border-radius: 5px 5px 5px 100%;}
.pos-bottom-right{ right:0;bottom:0;  border-radius: 5px 5px 100% 5px;}

.lgreen{ background-color: var(--primary_light); }
.dgreen{ background-color: #77989b; }
.lpurple{ background-color: var(--secondary_light); }
.dpurple{ background-color: #707088; }



.space-h{ width:var(--space_w_largest_side); height:var(--space_side); }
.space-v{ width:var(--space_side); height:var(--space_h_largest_side); }

.space-top-1{ left: var(--space_extern_margin); top: 0;}
.space-top-2{ right: var(--space_extern_margin); top: 0;}

.space-bottom-1{ left: var(--space_extern_margin); bottom: 0;}
.space-bottom-2{ right: var(--space_extern_margin); bottom: 0;}

.space-left-1{ left:0; top:var(--space_extern_margin);}
.space-left-2{ left:0; bottom:var(--space_extern_margin);}

.space-right-1{ right:0; top:var(--space_extern_margin);}
.space-right-2{ right:0; bottom:var(--space_extern_margin);}