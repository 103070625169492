@font-face {
  font-family: 'Augusta';
  src: url('../fonts/Augusta.ttf') format('truetype');
}

:root
{
  /*side of all corner squares and smallest side of spaces*/
  --space_side: 80px;
  /*half margin between 2 spaces*/
  --space_margin: 10px;
  /*half total margin in a screen side (top, right, bottom and left)*/
  --space_tot_margin: calc(3 * var(--space_margin));
  /*calculation of largest side of horizontal space (top and bottom screen side)*/
  --space_w_largest_side: calc(50% - var(--space_tot_margin) - var(--space_side));
  /*calculation of largest side of vertical space (left and right screen side)*/
  --space_h_largest_side: calc(50% - var(--space_tot_margin) - var(--space_side));
  /*position external spaces*/
  --space_extern_margin: calc(var(--space_side) + 2 * var(--space_margin));

  /*COLORS*/
  --primary: #34898f;
  --primary_light: #c6e7e9;
  --primary_dark: #324b4d;
  --secondary: #7b79a5;
  --secondary_light:#aeabd4;
  --secondary_dark: #494971;
}

html
{
  background-image: url("../img/library_background.png");
  width: 100%; height: 100%;
}

a:visited{ color:var(--secondary_dark) }
a{ color: var(--secondary_light) }

body {
  margin: 0;
  width:100%; height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav
{
  display: flex;
  justify-content: center;
  align-items: center;
}

main
{
  display: flex;
  flex-direction: column;
  align-items: center;
  
  width:99vw; min-height:200vh;
  margin:auto;

  font-family: Augusta, "Comic Sans MS", "Times New Roman";
  font-size: calc(10px + 2.5vmin);
}

legend
{
  font-size: calc(10px + 4vmin);
}

.title
{
  background-color: #000;
  
  border:1px #888 solid;
  border-radius: 5px;

  font-weight:normal;
  /*text-decoration:underline;*/
  color: var(--primary);
}
.subtitle
{
    font-weight:normal;
    font-style:italic;
    color: var(--secondary_light);
}
.text{ text-align:left; }